/* Imports */
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";

/* Navigation CSS for the header */
.nav-header {
	background-color: #FFF;
	width: 100%;
	display: flex;
	flex: 1;
	justify-content: space-between;
}

.nav-header > a {
	padding: 1rem;
}

/* Index styles */
#featured-bad-sites {
	margin-top: 2rem;
	position: relative;
	padding: 0 1rem;
}

.overlay {
	position: absolute;
	top: -10%;
	left: -2%;
	right: -2%;
	z-index: 20;
}

#featured-text-container {
	margin: 0 1rem;
	padding-top: 1rem;
}

@media (min-width: 576px) {
	#featured-bad-sites {
		margin-top: 1rem;
		padding: 0 2rem;
	}
	.overlay {
		top: -11%;
		right: -1%;
	}

	#featured-text-container {
		margin: 0 4rem;
	}
}

@media (min-width: 768px) {
	#featured-bad-sites {
		padding: 0 4rem;
	}
	.overlay {
		left: 4%;
		right: 4%;
	}
}

#carousel-sites {
    max-width: 741px;
	margin: 0 auto 2rem;
}

/* Pull quotes on several pages */
.pull-quote {
	margin-bottom: 1rem;
	padding: .5rem;
	border-top: 1px solid #AAA;
	border-bottom: 1px solid #AAA;
	background-color: #EEE;
	font-family: "Comic Sans MS", sans-serif;
	text-align: center;
	font-size: .9rem;
}

.pull-quote strong {
	color: #F00;
}

/* Styles for the primary content container */

.main-content {
	background-color: transparent;
	justify-content: center;
	width: 100%;
	padding-bottom: 2rem;
}

.content-wrapper {
	background: url(/img/containerBackground.gif);
	background-size: 100% 100%;
}

.container-fluid.container-xl {
	max-width: 955px;
    padding: 0;
 }

footer {
	a {
		&,
		&:hover,
		&:focus {
			color: black;
		}
	}
}

/* Bad website styles */

.website-content {
	padding-left: 1rem;
	padding-right: 1rem;
	padding-bottom: 2rem;
}

.bad-site-description{
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}

.bad-site-description h2 {
	color: #403D82;
	font-weight: bold;
	font-size: 1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.divider {
	margin: 1rem 0;
}

/* Articles styles */

.article-teaser {
	background: url(/img/getHelpTeaserBackground.gif);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	padding: 0 1.5rem 0 1.5rem;
	margin: 0 auto;
	max-width: 700px;
	min-height: 139px;

	h2 {
		padding-top: .25rem;
		margin-top: .25rem;
		padding-bottom: .25rem;
		margin-bottom: -1rem;
		font-size: 1rem;
		font-weight: bold;

		@include media-breakpoint-only("xs") {
			padding-top: .5rem;
		}

		a {
			color: #FFF;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	p {
		width: auto;
		font-size: .9rem;
		margin-top: -1rem;
		line-height: normal;
		padding-right: 2rem;

		&:first-of-type {
			margin-top: 4.5rem;

			@include media-breakpoint-only("xs") {
				margin-top: 4rem;
			}
			@include media-breakpoint-up("sm") {
				margin-top: 2rem;
			}
		}
		&:last-of-type {
			padding-bottom: 2rem;
		}
	}
}

/* Pagination styles */

.page-link {
	text-decoration: none !important;
}

.pagination {
	flex-wrap: wrap;

	.prev.disabled, .next.disabled { display: none; }

	.page-item.active {
		.page-link {
			background-color: #DF2B51;
			border-color: #DF2B51;
		}
	}
}

/* Form stuff */

.specialists {
	color: #403D82;
	font-weight: bold;
	font-size:2rem;
	text-align: right;
	margin-right: 5rem;
}

#form-container {
	background: url(/img/contactFormBackground.gif);
	background-size: 100% 100%, contain;
	background-repeat-x: no-repeat;
	font-weight: bold;
}

.error-message, .error {
	color: #C00;
}

/* General style tweaks */

body {
	background-color: #FEEB00;
	margin: 1rem 0rem;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 10pt;
}

h1 {
	color: #DF2B51;
	font-weight: bold;
	font-size: 16pt;
}

p {
	font-size: 0.9rem;
}

a {
	color: #336699;
	text-decoration: underline;
}

a:hover {
	color: #FF0000;
	text-decoration: none;
}

img {
	vertical-align: top;
}

